var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"home-content"},[_c('div',{staticClass:"block home-banner"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.carousel),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{attrs:{"src":item.imgUrl,"alt":"轮播图"},on:{"click":function($event){return _vm.gotoGoods(item)}}})])}),0),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"})])]),(_vm.seckillGoodsList && _vm.seckillGoodsList.length !== 0)?_c('div',{staticClass:"seckill-zone"},[_c('div',{staticClass:"seckill-tit page-item-tit"},[_c('span',[_vm._v("秒杀专区")]),_c('span',{staticClass:"seckill-all",on:{"click":function($event){return _vm.gotoSeckellAll('SeckillAll')}}},[_c('div',{staticClass:"seckill-all-div"},[_vm._v("查看更多")]),_vm._m(0)])]),_c('div',{staticClass:"goods-content-box"},_vm._l((_vm.seckillLiance),function(item){return _c('div',{key:item.id,staticClass:"seckill-item goods-item-box",on:{"click":function($event){return _vm.goGoodDetail(item.id, '1')}}},[_c('div',{staticClass:"goods-img"},[_c('img',{attrs:{"src":item.firstPic || _vm.defaultImg,"alt":""}})]),_c('div',{staticClass:"seckill-statistic-time"},[_c('el-statistic',{attrs:{"value":_vm._f("handleTime")(item.endTime),"value-style":{
              color: '#ffffff',
              fontSize: '17px',
              lineHeight: '35px',
            },"format":"DD天HH时mm分钟ss秒","time-indices":"","title-style":"color:#fff"}})],1),_c('div',{staticClass:"goods-name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"goods-intr"},[_c('span',[_vm._v(_vm._s(item.expansionSize)+" "+_vm._s(item.year))])]),_c('div',{staticClass:"discount-box"}),_c('div',{staticClass:"seckillModule"},[_c('div',{staticClass:"goods-discount-box"},[_c('div',{staticClass:"seckillTit"},[_vm._v("劲爆价")]),_c('div',{staticStyle:{"font-weight":"900","color":"#fba147","font-size":"19px"}},[_vm._v(" ￥"+_vm._s(item.sellPrice)+" ")])])])])}),0)]):_vm._e(),_c('div',[_c('div',{staticClass:"goods-box"},[_c('div',{staticClass:"goods-tit page-item-tit"},[_c('span',[_vm._v("热卖商品")]),_c('span',{staticClass:"seckill-all",on:{"click":function($event){return _vm.gotoSeckellAll('GoodsAll')}}},[_c('div',{staticClass:"seckill-all-div"},[_vm._v("查看更多")]),_vm._m(1)])]),_c('div',{staticClass:"goods-content-box"},_vm._l((_vm.allAppliance),function(item){return _c('div',{key:item.id,staticClass:"goods-item-box",on:{"click":function($event){return _vm.goGoodDetail(item.policyId)}}},[_c('div',{staticClass:"goods-img"},[_c('img',{attrs:{"src":item.imgList[0] || _vm.defaultImg,"alt":""}})]),_c('div',{staticClass:"goods-name page-content-tit"},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"goods-intr"},[_vm._v(_vm._s(item.goodsIntroduction))]),_c('div',{staticClass:"goods-payPrice"},[_vm._v("¥"+_vm._s(item.payPrice)+"起")])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finger-img"},[_c('img',{attrs:{"src":require("../../src/assets/imgs/home/finger.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finger-img"},[_c('img',{attrs:{"src":require("../../src/assets/imgs/home/finger.png"),"alt":""}})])
}]

export { render, staticRenderFns }