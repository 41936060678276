<template>
  <div class="home-content" v-loading="isLoading">
    <!-- 轮播图 -->
    <div class="block home-banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in carousel"
            :key="index"
          >
            <!-- ref="bannerHeight" -->
            <img :src="item.imgUrl" @click="gotoGoods(item)" alt="轮播图" />
          </div>
        </div>
        <!-- <div class="swiper-wrapper" v-else>
          <div class="swiper-slide" v-for="(item,index) in staticBannerList" :key="index" >
            <img :src="item.imgUrl"  alt="轮播图" ref="bannerHeight" />
          </div>
          
        </div> -->
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!-- 轮播图END -->
    <!-- 秒杀专区 -->
    <div
      class="seckill-zone"
      v-if="seckillGoodsList && seckillGoodsList.length !== 0"
    >
      <div class="seckill-tit page-item-tit">
        <span>秒杀专区</span>
        <span class="seckill-all" @click="gotoSeckellAll('SeckillAll')">
          <div class="seckill-all-div">查看更多</div>
          <div class="finger-img">
            <img src="../../src/assets/imgs/home/finger.png" alt="" />
          </div>
        </span>
      </div>
      <div class="goods-content-box">
        <!-- @click="goGoodDetail(item.id,'1')" -->
        <div
          class="seckill-item goods-item-box"
          v-for="item in seckillLiance"
          :key="item.id"
          @click="goGoodDetail(item.id, '1')"
        >
          <div class="goods-img">
            <img :src="item.firstPic || defaultImg" alt="" />
          </div>
          <div class="seckill-statistic-time">
            <el-statistic
              :value="item.endTime | handleTime"
              :value-style="{
                color: '#ffffff',
                fontSize: '17px',
                lineHeight: '35px',
              }"
              format="DD天HH时mm分钟ss秒"
              time-indices
              title-style="color:#fff"
            >
            </el-statistic>
          </div>
          <div class="goods-name">{{ item.title }}</div>
          <!-- <div class="goods-intr">{{item.intro}}</div> -->
          <div class="goods-intr">
            <span>{{ item.expansionSize }}&nbsp;{{ item.year }}</span>
          </div>

          <div class="discount-box">
            <!-- <span>{{ item.expansionSize }}&nbsp;{{ item.year }}</span> -->
          </div>
          <div class="seckillModule">
            <div class="goods-discount-box">
              <div class="seckillTit">劲爆价</div>
              <!-- <div class="seckillTit" v-else>限时优惠</div> -->
              <div style="font-weight: 900; color: #fba147; font-size: 19px">
                ￥{{ item.sellPrice }}
              </div>
              <!-- <div class="goods-discount-originalPrice"><del><span>￥{{item.originalPrice}}</span></del></div> -->
            </div>
          </div>
          <!-- <div class="discount-box">
            <div class="goods-discount-box">秒杀价<span class="goods-discount-val">￥{{item.payPrice}}</span></div>
            <div class="goods-discount-box">原价<del><span class="goods-discount-val">￥{{item.originalPrice}}</span></del></div>
          </div> -->
        </div>
      </div>
      <!-- <div
        class="pagination"
        style="display: flex; justify-content: space-evenly"
      >
        <button
          @click="seckillpreviousPage"
          :disabled="seckillcurrentPage === 0"
        >
          上一页
        </button>
        <div class="page-info">
          第 {{ seckillcurrentPage + 1 }} 页 / 共 {{ seckillpageCount }} 页，共
          {{ seckillLiance.length }} 条
        </div>
        <button
          @click="seckillnextPage"
          :disabled="seckillcurrentPage === seckillpageCount - 1"
        >
          下一页
        </button>
      </div> -->
    </div>
    <div>
      <div class="goods-box">
        <div class="goods-tit page-item-tit">
          <span>热卖商品</span>
          <span class="seckill-all" @click="gotoSeckellAll('GoodsAll')">
            <div class="seckill-all-div">查看更多</div>
            <div class="finger-img">
              <img src="../../src/assets/imgs/home/finger.png" alt="" />
            </div>
          </span>
        </div>

        <div class="goods-content-box">
          <div
            class="goods-item-box"
            v-for="item in allAppliance"
            :key="item.id"
            @click="goGoodDetail(item.policyId)"
          >
            <div class="goods-img">
              <img :src="item.imgList[0] || defaultImg" alt="" />
            </div>
            <div class="goods-name page-content-tit">{{ item.goodsName }}</div>
            <div class="goods-intr">{{ item.goodsIntroduction }}</div>
            <div class="goods-payPrice">¥{{ item.payPrice }}起</div>
          </div>
        </div>
      </div>
      <!-- <div
        class="pagination"
        style="display: flex; justify-content: space-evenly"
      >
        <button @click="previousPage" :disabled="currentPage === 0">
          上一页
        </button>
        <div class="page-info">
          第 {{ currentPage + 1 }} 页 / 共 {{ pageCount }} 页，共
          {{ allAppliance.length }} 条
        </div>
        <button @click="nextPage" :disabled="currentPage === pageCount - 1">
          下一页
        </button>
      </div> -->
    </div>
  </div>
</template>
<script>
// import GoodsList from '../components/GoodsList.vue';
// import { get } from "vue-cookie";
// import wx from 'weixin-js-sdk';
import Swiper from 'swiper'
import { validateGoodStatus, validateSeckillGoodStatus } from '@/utils'
import 'swiper/css/swiper.min.css'
import { getwxShare } from '@/utils/wexinShare'
import {
  getHomeBannerList,
  getGoodList,
  getHomeSeckillGoodsList,
  wxCode,
} from '@/api/home'
export default {
  components: {
    // GoodsList
  },
  data() {
    return {
      // 秒杀商品列表
      ddd: new Date('2023-09-30 00:00:00'),
      bannerHeight: '',
      config: null,
      isLoading: true, //控制加载层
      defaultImg: require('@/assets/imgs/no-image.png'),
      staticBannerList: [
        { imgUrl: require('../assets/imgs/bannaer33333.jpg') },
        { imgUrl: require('../assets/imgs/banner155555.jpg') },
        { imgUrl: require('../assets/imgs/mybanner11.jpg') },
        { imgUrl: require('../assets/imgs/mubanner222.jpg') },
      ],
      carousel: [
        // { imgUrl: require("../assets/imgs/bannaer33333.jpg") },
        // { imgUrl: require("../assets/imgs/banner155555.jpg") },
        // { imgUrl: require("../assets/imgs/mybanner11.jpg") },
        // { imgUrl: require("../assets/imgs/mubanner222.jpg") }
      ], // 轮播图数据
      phoneList: '', // 手机商品列表
      miTvList: '', // 小米电视商品列表
      applianceList: '', // 家电商品列表
      applianceHotList: '', //热门家电商品列表
      accessoryList: '', //配件商品列表
      accessoryHotList: '', //热门配件商品列表
      protectingShellList: '', // 保护套商品列表
      chargerList: '', //充电器商品列表
      applianceActive: 1, // 家电当前选中的商品分类
      accessoryActive: 1, // 配件当前选中的商品分类
      allAppliance: [], // 假设商品数据从接口获取并填充到此数组中
      currentPage: 0,
      // pageSize: 15,
      pageSize: 12,
      seckillLiance: [], // 秒杀
      seckillcurrentPage: 0,
      seckillpageSize: 8,
    }
  },
  computed: {
    pagedGoodsList() {
      const start = this.currentPage * this.pageSize
      const end = start + this.pageSize
      return this.allAppliance.slice(start, end)
    },
    seckillGoodsList() {
      const start = this.seckillcurrentPage * this.seckillpageSize
      const end = start + this.seckillpageSize
      return this.seckillLiance.slice(start, end)
    },
    pageCount() {
      return Math.ceil(this.allAppliance.length / this.pageSize)
    },
    seckillpageCount() {
      return Math.ceil(this.seckillLiance.length / this.seckillpageSize)
    },
  },
  created() {
    this.getBannerList()
    this.getGoodsList()
    this.getSeckillGoodsList()
    this.getWxcode()
    this.getCode()
  },
  watch: {},
  filters: {
    handleTime(value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = '0' + (date.getMonth() + 1)
      const day = '0' + date.getDate()
      const hours = '0' + date.getHours()
      const minutes = '0' + date.getMinutes()
      const seconds = '0' + date.getSeconds()
      return new Date(`${year}/${month}/${day} ${hours}:${minutes}:${seconds}`)
    },
  },
  mounted(){
    this.initWeChatConfig()
  },
  methods: {
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      // const parsedURL = new URL(url);
      // const domain = parsedURL.origin;
      var goodsType = 3
      var params={
        url:url,
        goodsId:3,
        goodsType:goodsType
      }
    const path ='#/home';
     getwxShare(params,url,goodsType,path,'入驻商城首页')
    },
    // 获取url的参数
    getUrlParams() {
      let str = window.location.href
      const codeMatch = str.match(/[?&]code=([\w]+)/) // 匹配 ?code= 或 &code= 后跟着任意字母数字字符的部分
      const code = codeMatch && codeMatch[1] // 取出匹配到的代码
      return code
    },
    // getQueryString (name) {//hash模式中需要用到解析url中的参数的函数
    //   var arr = (location.hash || "")
    //     .substr(location.hash.indexOf("?") + 1)
    //     .replace(/^#/, "")
    //     .split("&");
    //   // var params = {};
    //   for (var i = 0; i < arr.length; i++) {
    //     var data = arr[i].split("=");
    //     if (data.length == 2 && data[0] == name) {
    //       return data[1];
    //     }
    //   }
    //   return null;
    // },
    isWX() {
      var wxD = window.navigator.userAgent.toLowerCase()
      // console.log(wx);
      if (wxD.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    getCode() {
      if (!this.$store.getters.getUser) {
        return
      }
      // this.getUrlParams()
      if (this.isWX() == true && this.$device.mobile) {
        let paramsObj = this.getUrlParams()
        if (paramsObj) {
          // 设置到config备用
          this.config.code = paramsObj
          // 获取我的其他资料，图片等等
          // getMyData(openid)
          const params = {
            code: paramsObj,
          }
          wxCode(params).then((data) => {
            console.log(data)
          })
        } else {
          const appid = 'wx58ec8229ae98ebc3' // 公众号的唯一标识
          //授权后重定向的回调链接地址， 请使用 urlEncode 对链接进行处理
          const redirect_uri = encodeURIComponent(
            'http://gw-front.jieyingsoft.com/#/'
          )
          //应用授权作用域：snsapi_userinfo（弹出授权页面，可通过openId拿到昵称、性别、所在地。）
          const scope = 'snsapi_userinfo'
          // 应用授权作用域：snsapi_base （不弹出授权页面，直接跳转，只能获取用户openId）
          //  scope: "snsapi_base"
          const urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=STATE#wechat_redirect `
          window.location.href = urls
        }
      }
    },
    // 给后端提交code
    async getWxcode() {
      if (!this.$store.getters.getUser) {
        return
      }
      if (this.isWX() == true && this.$device.mobile) {
        let paramsObj = this.getUrlParams()
        const params = {
          code: paramsObj,
          phone: this.$store.getters.getUser.mobile,
        }
        const res = wxCode(params)
        console.log(res)
      }
    },
    // 获取banner
    async getBannerList() {
      const res = await getHomeBannerList()
      if (res.pic == '') {
        this.carousel = this.staticBannerList
        this.$nextTick(() => {
          this.initSwiper()
        })
      } else {
        this.carousel = res.pic
        this.$nextTick(() => {
          this.initSwiper()
        })
      }
      this.isLoading = false
    },
    initSwiper() {
      new Swiper('.swiper-container', {
        // 配置选项
        autoplay: {
          delay: 3000,
          // disableOnInteraction: false,
          autoplay: true, //设置自动循环播放
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next', // 右切换按钮元素
          prevEl: '.swiper-button-prev', // 左切换按钮元素
        },
      })
    },
    gotoGoods(item) {
      if (item.gotoUrl) {
        this.$router.push({
          path: '/goods/details',
          query: { productID: item.gotoUrl },
        })
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount - 1) {
        this.currentPage++
      }
    },
    seckillpreviousPage() {
      if (this.seckillcurrentPage > 0) {
        this.seckillcurrentPage--
      }
    },
    seckillnextPage() {
      if (this.seckillcurrentPage < this.seckillpageCount - 1) {
        this.seckillcurrentPage++
      }
    },
    // 获取商品列表
    async getGoodsList() {
      const res = await getGoodList('')
      if (res.code == 200) {
        this.isLoading = false

        if (res.data.ruGoodsList && res.data.ruGoodsList.length > 12) {
          this.allAppliance = res.data.ruGoodsList.slice(0, 12)
        } else {
          this.allAppliance = res.data.ruGoodsList || []
        }
      }
    },
    // h5时跳转到更多秒杀商品页面
    gotoSeckellAll(type) {
      this.$router.push({
        name: type,
      })
    },
    // 获取秒杀商品列表
    async getSeckillGoodsList() {
      // let paramsObj = this.getUrlParams()
      //   const params = {
      //     code: paramsObj,
      //   }
      const res = await getHomeSeckillGoodsList()
      if (res.code == 200) {
        if (res.data.seckillList && res.data.seckillList.length > 4) {
          this.seckillLiance = res.data.seckillList.slice(0, 4)
        } else {
          this.seckillLiance = res.data.seckillList || []
        }
      }
    },
    // 跳转商品详情
    async goGoodDetail(productID, type) {
      const isGrounding =
        type === '1'
          ? await validateSeckillGoodStatus(productID)
          : await validateGoodStatus(productID)
      if (isGrounding) {
        this.notifyError('当前商品已下架！')
        return
      }
      // 校验商品是否下架
      switch (type) {
        case '1':
          this.$router.push({
            path: '/goods/seckilldetails',
            query: { productID },
          })
          break
        default:
          this.$router.push({
            path: '/goods/details',
            query: { productID },
          })
          break
      }
    },
  },
  // mounted () {
  //   new Swiper('.swiper-container', {
  //     // 配置选项
  //     autoplay: {
  //       delay: 3000,
  //       // disableOnInteraction: false,
  //       autoplay:true//设置自动循环播放
  //     },
  //     observer: true,//修改swiper自己或子元素时，自动初始化swiper
  //     observeParents: true,//修改swiper的父元素时，自动初始化swiper
  //     pagination: {
  //       el: '.swiper-pagination',
  //       clickable: true,
  //     },
  //     loop: true,
  //     navigation: {
  //       nextEl: '.swiper-button-next', // 右切换按钮元素
  //       prevEl: '.swiper-button-prev' // 左切换按钮元素
  //     }
  //   })
  // }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.finger-img img {
  width: 33px;
  height: 30px;
  padding-top: 3px;
}
.goods-box,
.seckill-zone {
  width: $type-area-width;
  margin: 0 auto;
  padding: 40px 0;
  .seckill-tit,
  .goods-tit {
    display: flex;
    justify-content: space-between;
    width: 99%;
    margin: 0 auto;
    .seckill-all {
      // align-items: flex-end;
      // flex-basis: calc(20%);
      // display: none;
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      align-items: center;
      .seckill-all-div {
        width: 100%;
      }
    }
  }

  .pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* 可以根据需要添加其他样式属性 */
  }

  .pagination-container {
    margin-top: 10px;
  }
  .goods-content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
    margin: 0 auto;
    .goods-item-box {
      cursor: pointer;
      // width: 18.5%;
      width: 24%;
      height: auto;
      padding: 0px 0 1.5625rem;
      margin: 0.5%;
      /* margin-right: 1.04167rem; */
      margin-bottom: 1.04167rem;
      background-color: #fff;
      border-radius: 0.3125rem;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .seckill-statistic-time {
        height: 40px;
        background-color: #fa8919;
        opacity: 0.8;
        text-align: center;
      }
      .goods-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 18px;
        font-weight: 400;
        color: #333;
        text-align: center;
        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;
        margin: 25px 10px 0;
      }
      .goods-img {
        display: block;
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 100%;
        // height: 195px;
        // height: auto;
        height: 244px;
        margin: 0 auto;
        border-radius: 0.3125rem 0.3125rem 0 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .goods-intr {
        white-space: normal; /* 多行显示 */
        display: -webkit-box;
        margin: 10px 20px;
        // font-size: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #fba147;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .goods-payPrice {
        display: -webkit-box;
        margin: 10px 20px;
        font-size: 18px;
        font-weight: 600;
        color: #fa8919;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .discount-box {
        // display: flex;
        // justify-content: center;
        // margin-top: 15px;
      }
      .goods-discount-val {
        color: #d50f1c;
        font-weight: bold;
        font-size: 20px;
        margin-left: 5px;
      }
      .seckillModule {
        div {
          // margin-right: 5px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .preferential {
          font-size: 0.625rem;
          /* width: 100%; */
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
        }
        .goods-discount-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;
          .seckillTit {
            border: 1px solid #fa8919;
            background: #fa8919;
            color: white;
            padding: 3px;
            // width: 49px;
            text-align: center;
            border-radius: 11px;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
      .goods-discount-originalPrice {
        margin-left: 10px;
        font-size: 8px;
        display: flex; /* 将容器设置为flex布局 */
        align-items: center; /* 垂直居中对齐 */
        color: #b0b0b0;
      }
      .goods-discount-discount {
        font-size: 12px;

        /* 可选样式 */
        // color: #b0b0b0;
      }
      .gobuy-btn {
        display: flex;
        flex-direction: column;
        background-color: #d50f1c;
        padding: 8px 15px;
        border-radius: 6px;
        color: #fff;
        span {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .goods-item-box:hover {
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
    }
  }
}
.goods-box {
  min-height: 50vh;
}
.home-banner {
  top: 10px;
  width: 100%;
  height: 600px;
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .home-banner .swiper-wrapper .swiper-slide img {
    margin-top: 2px;
  }
  .home-banner {
    height: 210px;
    // min-height: 200px !important;
    // max-height: 300px !important;
    // height: 300px !important;
    .swiper-slide {
      height: 210px !important;
      img {
        width: 100% !important;
        height: 210px !important;
        object-fit: cover;
        // min-height: 200px !important;
        // max-height: 300px !important;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .goods-img {
    max-height: 130px;
  }
  .goods-box,
  .seckill-zone {
    width: 95%;
    padding: 0;
    margin-top: 15px;
    background-color: initial;
    .seckill-all {
      display: flex !important;
    }
    .goods-item-box {
      width: 48% !important;
      padding: 0 !important;
      padding-bottom: 30px !important;
      margin: 0 !important;
      background-color: #fff !important;
      margin-bottom: 15px !important;
    }
    .goods-content-box {
      justify-content: space-between;
      margin-top: 10px;
      width: 100% !important;
    }
  }
  .seckill-zone {
    .goods-item-box {
      width: 100% !important;
      .goods-intr {
        font-size: 15px !important;
      }
    }
    .goods-img {
      max-height: 200px;
    }
  }
  .pagination {
    display: flex;
    justify-content: space-evenly;
    display: none !important;
  }

  .page-info {
    margin: 0 20px;
  }
}
</style>
<style lang="scss">
.home-banner {
  .swiper-container {
    height: 100%;
  }
  .swiper-wrapper {
    height: 100%;
    .swiper-slide {
      width: 100%;
      height: 100%;
      img {
        margin-top: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper-button-prev {
    left: 30px;
  }
  .swiper-button-next {
    right: 30px;
  }
  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 5px;
  }
}
</style>
